import React from 'react';
import styled, { ThemeContext } from 'styled-components';

import { TwitterBirdSvg, TwitterVerifiedCheckSvg } from './TwitterIconSvg';

export const Article = styled.article`
  width: 100%;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(3)};
  font-family: 'Helvetica Neue';

  img.emoji {
    width: 18px;
    height: 18px;
  }
`;

export const ArticleHeader = styled.header.attrs(({ children, withBird = true }) => ({
  children: (
    <React.Fragment>
      {children}
      {withBird ? (
        <div style={{ height: '24px', width: '24px' }}>
          <TwitterBirdSvg />
        </div>
      ) : null}
    </React.Fragment>
  ),
}))`
  display: flex;
  justify-content: space-between;
`;

export const AuthorInfo = styled.address`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const AuthorProfileImage = styled.a`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background: ${({ theme }) => theme.color.twitterGrey};
  margin-right: ${({ theme }) => theme.spacing(1)};
  border-radius: ${({ size }) => size / 2}px;

  * {
    border-radius: ${({ size }) => size / 2}px;
  }

  &:hover .gatsby-image-wrapper {
    opacity: 0.85;
  }
`;

export const AuthorDisplayName = styled.div.attrs(props => ({
  children: (
    <React.Fragment>
      <span>
        {props.children}
      </span>
      {props.isVerified ? <TwitterVerifiedCheckSvg /> : null}
    </React.Fragment>
  ),
}))`
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  white-space: break-spaces;

  span {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export const AuthorAnchor = styled.a`
  &:hover ${AuthorDisplayName} {
    text-decoration: underline;
  }
`;

export const AuthorHandle = styled.div`
  color: ${({ theme }) => theme.color.twitterGrey};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

export const TextContent = styled.blockquote`
  margin: 0;
  padding: ${({ theme, padding = 2 }) => theme.spacing(padding)} 0;
  font-size: 20px;
  line-height: 1.4;

  img.emoji {
    vertical-align: text-bottom;
  }
  a {
    color: ${({ theme }) => theme.color.twitterBlue};
  }
  a:hover {
    text-decoration: underline;
  }
`;

export const NestedContent = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const PostedDetails = styled.time`
  color: ${({ theme }) => theme.color.twitterGrey};
  font-size: 13px;
  font-style: normal;
  line-height: 18px;

  a:hover {
    text-decoration: underline;
  }
`;

export const EngagementCounter = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.color.twitterLightGrey};
  margin-top: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  border-bottom: 1px solid ${({ theme }) => theme.color.twitterLightGrey};
  padding-bottom: ${({ theme }) => theme.spacing(2)};

  & > * + * {
    margin-left: ${({ theme }) => theme.spacing(4)};
  }
`;

export const EngagementCount = styled.a.attrs(props => ({
  children: (
    <React.Fragment>
      <div className="icon">{props.Icon}</div>
      <div className="value">{props.value}</div>
    </React.Fragment>
  ),
}))`
  color: ${({ theme }) => theme.color.twitterGrey};
  display: flex;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  align-items: center;

  .icon {
    width: 24px;
    height: 24px;
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }

  &:hover * {
    fill: ${({ theme, hoverFill }) => theme.color[hoverFill]};
    color: ${({ theme, hoverFill, hoverStroke }) => theme.color[hoverFill || hoverStroke]};
    stroke: ${({ theme, hoverStroke }) => theme.color[hoverStroke]};
    text-decoration: underline;
  }
`;

export const NestedArticle = styled.article`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.twitterLightGrey};
  padding: ${({ theme }) => theme.spacing(1.5)};
  border-radius: ${({ theme }) => theme.borderRadii.standard};

  &:hover {
    background: ${({ theme }) => theme.color.grey10};
  }
`;
