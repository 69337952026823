import React from 'react';

export const TwitterBirdSvg = () => (
  <svg
    version="1.1"
    id="Logo"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 248 204">
    <g id="Logo_1_">
      <path
        id="white_background"
        fill={'#1D9BF0'}
        d="M221.95,51.29c0.15,2.17,0.15,4.34,0.15,6.53c0,66.73-50.8,143.69-143.69,143.69v-0.04
      C50.97,201.51,24.1,193.65,1,178.83c3.99,0.48,8,0.72,12.02,0.73c22.74,0.02,44.83-7.61,62.72-21.66
      c-21.61-0.41-40.56-14.5-47.18-35.07c7.57,1.46,15.37,1.16,22.8-0.87C27.8,117.2,10.85,96.5,10.85,72.46c0-0.22,0-0.43,0-0.64
      c7.02,3.91,14.88,6.08,22.92,6.32C11.58,63.31,4.74,33.79,18.14,10.71c25.64,31.55,63.47,50.73,104.08,52.76
      c-4.07-17.54,1.49-35.92,14.61-48.25c20.34-19.12,52.33-18.14,71.45,2.19c11.31-2.23,22.15-6.38,32.07-12.26
      c-3.77,11.69-11.66,21.62-22.2,27.93c10.01-1.18,19.79-3.86,29-7.95C240.37,35.29,231.83,44.14,221.95,51.29z"
      />
    </g>
  </svg>
);

export const TwitterVerifiedCheckSvg = () => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M6.85883 2.24264C7.73495 1.26398 9.26718 1.26398 10.1433 2.24264L10.2275 2.33667C10.674 2.83543 11.323 3.10425 11.9914 3.0673L12.1174 3.06033C13.4289 2.98782 14.5124 4.07127 14.4398 5.3828L14.4329 5.50881C14.3959 6.17722 14.6647 6.8262 15.1635 7.2727L15.2575 7.35688C16.2362 8.233 16.2362 9.76523 15.2575 10.6413L15.1635 10.7255C14.6647 11.172 14.3959 11.821 14.4329 12.4894L14.4398 12.6154C14.5124 13.927 13.4289 15.0104 12.1174 14.9379L11.9914 14.9309C11.323 14.894 10.674 15.1628 10.2275 15.6616L10.1433 15.7556C9.26718 16.7342 7.73495 16.7342 6.85883 15.7556L6.77466 15.6616C6.32815 15.1628 5.67917 14.894 5.01076 14.9309L4.88475 14.9379C3.57323 15.0104 2.48978 13.927 2.56228 12.6154L2.56925 12.4894C2.6062 11.821 2.33739 11.172 1.83862 10.7255L1.74459 10.6413C0.765935 9.76523 0.765936 8.233 1.74459 7.35688L1.83862 7.2727C2.33739 6.8262 2.6062 6.17722 2.56925 5.5088L2.56228 5.3828C2.48978 4.07127 3.57323 2.98782 4.88475 3.06033L5.01076 3.0673C5.67917 3.10425 6.32815 2.83543 6.77466 2.33667L6.85883 2.24264Z"
        fill="#3AA1EC"
      />
      <path
        d="M5.63623 9.00013L7.54532 10.9092L10.8862 6.61377"
        stroke="white"
        strokeWidth="1.43182"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="17" height="17" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const TwitterLikeSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9859 18.5188C12.4512 18.922 11.7141 18.922 11.1794 18.5188L8.88354 16.7872C5.22234 13.1224 4.7998 11.8828 4.7998 8.92437C4.7998 6.49952 6.69393 4.80005 8.88354 4.80005C10.1205 4.80005 11.3078 5.43569 12.0826 6.43231C12.8575 5.43569 14.0448 4.80005 15.2818 4.80005C17.4714 4.80005 19.1998 6.49952 19.1998 8.92437C19.1998 11.8828 18.943 13.1224 15.2818 16.7872L12.9859 18.5188Z"
      stroke="#556470"
      strokeWidth="1.3"
    />
  </svg>
);

export const TwitterCommentSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6328 15.487H11.2828V14.837H10.6328V15.487ZM10.6328 18.2771H9.98281V19.4136L10.9624 18.8374L10.6328 18.2771ZM16.0536 15.0884L15.7957 14.4918L15.7587 14.5078L15.724 14.5282L16.0536 15.0884ZM6.4 10.6055C6.4 8.26842 8.29454 6.37388 10.6316 6.37388V5.07388C7.57657 5.07388 5.1 7.55045 5.1 10.6055H6.4ZM10.6316 14.837C8.29454 14.837 6.4 12.9425 6.4 10.6055H5.1C5.1 13.6605 7.57657 16.137 10.6316 16.137V14.837ZM10.6328 14.837H10.6316V16.137H10.6328V14.837ZM11.2828 18.2771V15.487H9.98281V18.2771H11.2828ZM15.724 14.5282L10.3032 17.7169L10.9624 18.8374L16.3832 15.6487L15.724 14.5282ZM18.35 10.6055C18.35 12.3455 17.2996 13.8417 15.7957 14.4918L16.3115 15.6851C18.2744 14.8366 19.65 12.8824 19.65 10.6055H18.35ZM14.1184 6.37388C16.4555 6.37388 18.35 8.26842 18.35 10.6055H19.65C19.65 7.55045 17.1734 5.07388 14.1184 5.07388V6.37388ZM10.6316 6.37388H14.1184V5.07388H10.6316V6.37388Z"
      fill="#556470"
    />
  </svg>
);

export const TwitterRetweetSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 8.71154L6.29438 6M6.29438 6L8.58876 8.71154M6.29438 6V15.5C6.29438 16.7426 7.30174 17.75 8.54438 17.75H13.9423"
      stroke="#556470"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
    <path
      d="M20.2695 15.0385L17.9752 17.75M17.9752 17.75L15.6808 15.0385M17.9752 17.75V8.25C17.9752 7.00736 16.9678 6 15.7252 6H10.3272"
      stroke="#556470"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
  </svg>
);
