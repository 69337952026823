import React from 'react';
import styled, {css} from 'styled-components';

import {Large} from '../text';

export const sectionStandardLayout = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
`;

export const sectionStandardPadding = css`
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(4)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding-left: ${({ theme }) => theme.spacing(3)};
    padding-right: ${({ theme }) => theme.spacing(3)};
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Section = styled.section.attrs(props => ({
  children: (
    <React.Fragment>
      {props.autoLayout ? (
        <div css={sectionStandardLayout}>
          <div css={sectionStandardPadding} style={props.style}>
            {props.children}
          </div>
        </div>
      ) : (
        props.children
      )}
    </React.Fragment>
  ),
}))`
  width: 100%;
`;

export const Frame = styled.div.attrs(props => ({
  children: (
    <React.Fragment>
      {props.heading ? (
        <div className="title">
          <Large as="h2" weight="bold" colored="nbPrimary" transform="uppercase">
            {props.heading}
          </Large>
        </div>
      ) : null}
      <div className="frame-foreground">{props.children}</div>
      <div className="frame-background" />
    </React.Fragment>
  ),
}))`
  width: 100%;
  position: relative;

  .frame-foreground,
  .frame-background {
    width: calc(100% - 24px);
    height: 100%;
    border: 2px solid ${({ theme }) => theme.color.nbPrimary};
    background: ${({ theme }) => theme.color.white};
  }

  .frame-background {
    top: 24px;
    right: 0;
    z-index: -1;
    position: absolute;
  }

  .title {
    top: -16px;
    width: 100%;
    display: flex;
    position: absolute;
    justify-content: center;
  }

  h2 {
    width: max-content;
    background: ${({ theme }) => theme.color.white};
    padding-left: ${({ theme }) => theme.spacing(4)};
    padding-right: ${({ theme }) => theme.spacing(4)};
    letter-spacing: 1.5px;
    font-size: 24px;
    font-weight: 900;
  }
`;

export const ctaAnchorBaseStyles = css`
  width: max-content;
  display: flex;
  align-items: center;

  svg {
    margin-left: ${({ theme }) => theme.spacing(2)};
  }

  * {
    color: ${({ theme }) => theme.color.nbPrimary} !important;
    stroke: ${({ theme }) => theme.color.nbPrimary};
    transition: ${({ theme }) => theme.transitions.all};
  }

  &:hover {
    svg {
      transform: translateX(4px);
    }
    
    * {
      color: ${({ theme }) => theme.color.blue50} !important;
      stroke: ${({ theme }) => theme.color.blue50};
    }
  }
`;
export const Cta = styled.div.attrs(props => ({
  children: (
    <a href={props.href}>
      {props.children}
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.6665 28.3333L30.9999 20L22.6665 11.6667"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1984 20.0583L9.83313 20.0583"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </a>
  ),
}))`
  ${sectionStandardLayout};
  ${sectionStandardPadding};
  font-weight: 500;
  max-width: 1376px;
  margin-top: ${({theme}) => theme.spacing(4)};
  a {
    ${ctaAnchorBaseStyles};
  }
  @media ${({theme}) => theme.breakpoints.xl} {
    padding-left: 0;
  }
  @media ${({theme}) => theme.breakpoints.xlMin} {
    margin-left: 0;
  } 
`;