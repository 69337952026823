import React from 'react';
import styled, { css } from 'styled-components';

const inputBaseCss = css`
  width: 100%;
  height: ${({ theme, fat }) => theme.spacing(fat ? 6 : 5)};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : null)};
  color: ${({ fontColor }) => (fontColor ? fontColor : null)};
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : null)};

  &::-webkit-input-placeholder {
    line-height: 18px !important;
  }

  &::-moz-placeholder {
    line-height: ${({ theme, fat }) => (fat ? '32px' : '24px')} !important;
  }

  /* Obscure Safari fix */
  background-clip: padding-box;
`;

const outlinedInputBaseCss = css`
  ${inputBaseCss}
  border: 1px solid ${({ theme, readOnly }) => (readOnly ? 'transparent' : theme.color.grey30)};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1.5)};
  transition: ${({ theme }) => theme.transitions.border};
  background: ${({ theme, readOnly }) => (readOnly ? theme.color.grey10 : null)};
  border-radius: ${({ theme }) => theme.spacing(0.5)};

  @media ${({ theme }) => theme.breakpoints.xs} {
    height: ${({ theme }) => theme.spacing(6)};
  }

  &:focus {
    border: ${({ theme, readOnly, hasValueSuffix }) => {
      if (readOnly) return 'initial';
      return `2px solid ${theme.color.nbPrimary} ${hasValueSuffix ? '!important' : ''}`;
    }}
  }

  &:placeholder-shown {
    padding-bottom: ${({ theme, fat }) => (fat ? theme.spacing(1.25) : theme.spacing(1))};
  }
`;

const invisibleInputBaseCss = css`
  ${inputBaseCss}
  border: none;
  text-align: center;
  height: ${({ theme }) => theme.spacing(3)} !important;
`;

const underlinedInputBaseCss = css`
  ${inputBaseCss}
  padding: ${({ theme }) => theme.spacing(1)};
  padding-top: ${({ theme, fat }) => theme.spacing(fat ? 2 : 1)};
  padding-left: 0;
  border: none;
`;

const outlinedInputWithPillCapCss = css`
  min-width: 0;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  &:focus {
    border-right: initial;
  }
`;

const OutlinedInputWrapper = styled.span`
  width: 100%;
  display: flex;
  position: relative;

  & input {
    min-width: 0;
  }

  & input:disabled {
    background-color: ${({ theme }) => theme.color.white};
    cursor: not-allowed;
  }

  /*pill cap styles*/
  & div {
    color: ${({ theme }) => theme.color.brandDark};
    height: auto;
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.color.grey30};
    border-left: ${({ theme, focused }) =>
      focused ? `1.75px solid ${theme.color.nbPrimary}` : `1px solid ${theme.color.grey30}`};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
    border-right: ${({ theme, focused }) => focused && `2px solid ${theme.color.nbPrimary}`};
    border-top: ${({ theme, focused }) => focused && `2px solid ${theme.color.nbPrimary}`};
    border-bottom: ${({ theme, focused }) => focused && `2px solid ${theme.color.nbPrimary}`};
    transition: ${({ theme, focused }) => theme.transitions.border};
  }
`;

const UnderlinedInputWrapper = styled.span`
  width: 100%;
  display: flex;
  position: relative;
  transition: border-bottom 0.2s ease;
  border-bottom: ${({ theme, focused }) =>
    focused ? `1px solid ${theme.color.nbPrimary}` : `1px solid ${theme.color.grey30}`};

  & input {
    min-width: 0;
  }

  /*pill cap styles*/
  & div {
    color: ${({ theme }) => theme.color.brandDark};
    height: auto;
    display: flex;
    align-items: center;
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

const InvisibleInputWrapper = styled.span`
  width: 100%;
  display: flex;
  position: relative;
  text-align: center;

  & input {
    min-width: 0;
  }
`;

const OutlinedInput = styled.input`
  ${outlinedInputBaseCss}
  ${({ hasPillCapLabel }) => (hasPillCapLabel ? outlinedInputWithPillCapCss : null)}
`;

const UnderlinedInput = styled.input`
  ${underlinedInputBaseCss}
`;

const InvisibleInput = styled.input`
  ${invisibleInputBaseCss}
`;

export {
  outlinedInputBaseCss,
  OutlinedInput,
  OutlinedInputWrapper,
  UnderlinedInput,
  UnderlinedInputWrapper,
  InvisibleInput,
  InvisibleInputWrapper,
};
