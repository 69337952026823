import React from 'react';
import styled from 'styled-components';

import {ctaAnchorBaseStyles, sectionStandardLayout, sectionStandardPadding,} from '../LandingPage.StyledComponents';


export const CommunitySection = styled.div`
  ${sectionStandardLayout};
  width: auto;
  margin-top: ${({ theme }) => theme.spacing(14)};
  border-top: 2px solid ${({ theme }) => theme.color.nbPrimary};
  padding-top: ${({ theme }) => theme.spacing(10)};
  // border-bottom: 2px solid ${({ theme }) => theme.color.nbPrimary};
  // padding-bottom: ${({ theme }) => theme.spacing(9)};
`;

export const TweetReelOverflow = styled.div`
  ${sectionStandardLayout};
  overflow: auto;
  max-width: 1376px;

  & > * {
    ${sectionStandardPadding};
  }
`;

export const TweetReel = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  & > * {
    margin-left: ${({theme}) => theme.spacing(4)};
    margin-right: ${({theme}) => theme.spacing(4)};
  }


  @media ${({ theme }) => theme.breakpoints.md} {
    align-items: center;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    & > * {
      margin-bottom: ${({ theme }) => theme.spacing(6)};
    }
  }
`;

export const ReelItem = styled.div`
  width: 381px;
  height: max-content;
  background: white;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  border-radius: ${({ theme }) => theme.spacing(1.5)};

  &:hover {
    transform: translateY(-2px);
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    width: 85vw;
  }
`;

export const CommunityCta = styled.div`
  ${sectionStandardLayout};
  ${sectionStandardPadding};
  width: auto;
  max-width: 1376px;
  margin: 0 auto;
  // margin-top: ${({ theme }) => theme.spacing(16)};
  margin-bottom: ${({ theme }) => theme.spacing(8)};

  a {
    ${ctaAnchorBaseStyles};
    text-align: center;
    margin: 0 auto;
    
    h2 {
      font-weight: 500;
      font-size: 32px;
      @media ${({ theme }) => theme.breakpoints.md} {
        font-size: 18px;
      }
    }
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    padding-left: 0;
  }
  @media ${({ theme }) => theme.breakpoints.xlMin} {
    //margin-left: 0;
  } 
`;
